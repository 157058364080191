/* eslint-disable no-unused-vars */
import { Box, useTheme } from "@mui/material";
import { tokens } from "../theme";
import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { CategoryRounded } from "@mui/icons-material";

ChartJS.register(LineElement, CategoryScale, PointElement, LinearScale);

const LineChart = ({ data }) => {
  var modifiedLabels = data.map(function (label) {
    // Split the label by '-' and return the second part
    return label.municipality.includes("-")
      ? label.municipality.split("-")[1] + " - " + label.total
      : label.municipality + " - " + label.total;
  });

  var chartData = {
    labels: modifiedLabels,
    datasets: [
      {
        label: "ANC Supporters",
        data: data.map((item) => item.anc_supporters),
        backgroundColor: ["hsl(111, 70%, 50%)"],
        borderColor: ["hsl(96, 70%, 50%)"],
        borderWidth: 1,
        grouped: true,
      },
      {
        label: "Non Supporters",
        data: data.map((item) => item.non_supporters),

        backgroundColor: ["hsl(229, 70%, 50%)"],
        borderColor: ["hsl(224, 70%, 50%)"],
        borderWidth: 1,
        grouped: true,
      },
      {
        label: "Not Captured",
        data: data.map((item) => item.not_captured),

        backgroundColor: ["hsl(344, 70%, 50%)"],
        borderColor: ["hsl(334, 70%, 50%)"],
        borderWidth: 1,
        grouped: true,
      },
      {
        label: "Undecided",
        data: data.map((item) => item.undecided),

        backgroundColor: ["hsl(9, 70%, 50%)"],
        borderColor: ["hsl(344, 70%, 50%)"],
        borderWidth: 1,
        grouped: true,
      },
      {
        label: "Total",
        data: data.map((item) => item.total),

        backgroundColor: ["hsl(274, 70%, 50%)"],
        borderColor: ["hsl(291, 70%, 50%)"],
        borderWidth: 1,
        grouped: true,
      },
    ],
  };
  //
  var options = {
    maintianAspectRation: true,
    responsive: true,
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 3000,
      },
    },
    legend: {
      labels: {
        fontSize: 10,
      },
    },
  };
  //
  return (
    <Box>
      <Line
        data={chartData}
        height={"150px"}
        maintianAspectRation={false}
        options={options}
      />
    </Box>
  );
};

export default LineChart;
