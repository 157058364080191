import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import React, { useState, useEffect } from "react";
import PieChart from "../../components/PieChart";

const IssuesCaptured = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  //
  const getData = () => {
    fetch(`https://ancfreestate.azurewebsites.net/Reports/getissues`)
      .then((response) => response.json())
      .then((res) => setData(res));
  };
  useEffect(() => {
    getData();
  }, []);
  //
  const columns = [
    //{ field: "id", headerName: "ID" },
    {
      field: "issue",
      headerName: "Issues",
      flex: 1,
    },
    {
      field: "occurance",
      headerName: "Times Reported",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
  ];

  return (
    <Box m="20px" paddingTop="65px" paddingLeft="70px">
      <Header title="Issues Captured" subtitle="All Issues Captured" />
      <Box
        gridColumn="span 4"
        gridRow="span 2"
        backgroundColor={colors.primary[400]}
        p="30px"
      >
        <Typography variant="h5" fontWeight="600">
          Issues Captured
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          alignContent="center"
          mt="20px"
          width="100%"
        >
          <PieChart isDashboard={true} />
          {/* <Typography
            variant="h5"
            color={colors.greenAccent[500]}
            sx={{ mt: "15px" }}
          >
            $48,352 revenue generated
          </Typography>
          <Typography>Includes extra misc expenditures and costs</Typography> */}
        </Box>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          // "& .name-column--cell": {
          //   color: colors.greenAccent[300],
          // },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.grey[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          getRowId={(row) => row.issue + row.occurance}
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default IssuesCaptured;
