import { Box, Button, useTheme, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import React, { useState, useEffect } from "react";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
// import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
// import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
// import LockOpenOutlinedIcon from "@mui/icons-material/LockClockOutlined";

const Users = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  //
  const getData = () => {
    fetch(`https://api.votescan.co.za/User`)
      .then((response) => response.json())
      .then((res) => setData(res));
  };
  useEffect(() => {
    getData();
  }, []);
  //
  const columns = [
    //{ field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "surname",
      headerName: "Surname",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "ward",
      headerName: "Ward",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "region",
      headerName: "Region",
      flex: 1,
    },
    {
      field: "cell",
      headerName: "Cell Number",
      flex: 1,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
    },
    {
      field: "province",
      headerName: "Province",
      flex: 1,
    },
    {
      field: "delegation",
      headerName: "Delegation",
      flex: 1,
    },
    // {
    //   field: "role",
    //   headerName: "Access Level",
    //   flex: 1,
    //   renderCell: ({ row: { role } }) => {
    //     return (
    //       <Box
    //         width="60%"
    //         m="0 auto"
    //         p="5px"
    //         display="flex"
    //         justifyContent="center"
    //         backgroundColor={
    //           role === "Elections Manger"
    //             ? colors.greenAccent[600]
    //             : role === "Elections Coordinator"
    //             ? colors.greenAccent[700]
    //             : colors.greenAccent[700]
    //         }
    //         borderRadius="4px"
    //       >
    //         {role === "Elections Admin" && <AdminPanelSettingsOutlinedIcon />}
    //         {role === "Elections Manager" && <SecurityOutlinedIcon />}
    //         {role === "Volunteer" && <LockOpenOutlinedIcon />}
    //         <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
    //           {role}
    //         </Typography>
    //       </Box>
    //     );
    //   },
    // },
  ];

  return (
    <Box m="20px" paddingTop="65px" paddingLeft="100px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="User Information" subtitle="All Users" />
        <Box>
          <Button
            href="/register-user"
            sx={{
              backgroundColor: colors.greenAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Add User
          </Button>
        </Box>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },

          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.grey[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Users;
