import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  Select,
  MenuItem,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
//charts
import LineChart from "../../components/LineChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import PieChart from "../../components/PieChart";
//
import GroupIcon from "@mui/icons-material/Group";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import GroupsIcon from "@mui/icons-material/Groups";
import { useNavigate } from "react-router-dom";
import ActiveElections from "../../components/ActiveElection";
//

const Dashboard = () => {
  const theme = useTheme();
  const login = window.localStorage.getItem("isLoggedIn");
  const colors = tokens(theme.palette.mode);
  //elections
  const [elections, setElections] = useState([]);
  const [activeElections, setActiveElections] = useState([]);
  //dashboad top numbers
  const [totals, setTotals] = useState([]);
  //for line graph
  const [lineGData, setLineGData] = useState([]);
  //Selected Region
  const [selectedRegion, setSelectedRegion] = useState("");
  //
  const [regionalTotals, setRegionalTotals] = useState();
  //CHAT FILTER OPTIONS
  const options = [
    { value: "All", label: "All" },
    { value: "Mangaung", label: "Mangaung" },
    { value: "Thabo Mofutsanyana", label: "Thabo Mofutsanyana" },
    { value: "Lejweleputswa", label: "Lejweleputswa" },
    { value: "Fezile Dabi", label: "Fezile Dabi" },
    { value: "Xhariep", label: "Xhariep" },
  ];
  const getElections = () => {
    fetch(`https://api.votescan.co.za/Elections`)
      .then((response) => response.json())
      .then((res) => {
        //
        let tempActive = [];
        let tempElections = [];
        //
        res.forEach((item) => {
          item.status === "active"
            ? tempActive.push(item)
            : tempElections.push(item);
        });
        setActiveElections(tempActive);
        setElections(tempElections);
      });
  };
  //
  const getNumbers = async () => {
    try {
      const url = `https://api.votescan.co.za/RecordedVoters/getprovinceprogress/aa/aa`;
      let result = await fetch(url);
      result = await result.json();
      setTotals(result[0]);
    } catch (error) {
      console.log("provincial numbers fetch =>" + error);
    }
  };
  const getRegionTotalAggregates = async () => {
    try {
      const url = `https://api.votescan.co.za/Reports/getregionalnumbers`;
      let result = await fetch(url);
      result = await result.json();
      if (result) {
        setRegionalTotals(result);
      }
    } catch (error) {
      console.log("provincial numbers fetch =>" + error);
    }
  };
  const getLineGraphData = (region) => {
    if (region === "All") {
      fetch(`https://api.votescan.co.za/Reports/getattitude`)
        .then((response) => response.json())
        .then((res) => {
          setLineGData(res);
        });
    } else {
      fetch(`https://api.votescan.co.za/Reports/getattitudebyregion/${region}`)
        .then((response) => response.json())
        .then((res) => {
          console.log(res);
          setLineGData(res);
        });
    }
  };
  const getRegion = (event) => {
    const region = event.target.value;
    console.log("selected =>" + region);
    setSelectedRegion(region);
    getLineGraphData(region);
  };
  useEffect(() => {
    getRegionTotalAggregates();
    getElections();
    getLineGraphData("All");
    getNumbers();
  }, []);

  return (
    <Box>
      <Box m="20px" paddingTop="65px" paddingLeft="100px">
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
        </Box>

        {/* GRID & CHARTS */}
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="140px"
          gap="20px"
        >
          {/* ROW 1 */}
          <Box gridColumn="span 8" display="flex" gap="20px">
            {/* stats box  */}
            <Box
              flex="1"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title={totals.surname}
                subtitle="Canvassed Voters"
                icon={
                  <GroupIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                  />
                }
              />
            </Box>
            <Box
              flex="1"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title={totals.names}
                subtitle="Total Voters"
                icon={
                  <GroupsIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                  />
                }
              />
            </Box>
            <Box
              gridColumn="span 4"
              flex="1"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title={totals.id_number}
                subtitle="Team Members"
                // increase={TeamPecentage}
                icon={
                  <Diversity3Icon
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                  />
                }
              />
            </Box>
          </Box>
          <Box gridColumn="span 4" height="200px">
            {activeElections.map((item) => {
              return (
                <Card>
                  <ActiveElections elections={item} />
                </Card>
              );
            })}
          </Box>

          {/* ROW 2 */}
          <Box gridColumn="span 8" gridRow="span 2">
            <Box
              gridColumn="span 12"
              p="0 30px"
              display="flex "
              justifyContent="space-between"
              alignItems="center"
              backgroundColor={colors.primary[400]}
            >
              <Box gridColumn="span 6">
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color={colors.grey[100]}
                  sx={{ marginBottom: 3 }}
                >
                  Voters' Attitudes
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignContent={"center"}
                alignItems={"center"}
                marginBottom="10px"
              >
                <Box gridColumn="span 4">
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color={colors.grey[100]}
                    sx={{ marginBottom: 3 }}
                    mt="25%"
                  >
                    Filter by region:
                  </Typography>
                </Box>
                <Box gridColumn="span 2">
                  <Select
                    borderColor={"lightgray"}
                    onChange={getRegion}
                    value={selectedRegion}
                    sx={{
                      backgroundColor: "#fff",
                      height: "30px",
                      width: "150px",
                      marginTop: "5%",
                      padding: "10",
                      borderWidth: 1,
                      borderColor: "lightgray",
                    }}
                  >
                    <MenuItem value="All" selected>
                      <em>All</em>
                    </MenuItem>
                    <MenuItem value="Fezile Dabi">Fezile Dabi Region</MenuItem>
                    <MenuItem value="Lejweleputswa">
                      Lejweleputswa District
                    </MenuItem>
                    <MenuItem value="Mangaung">Mangaung Metro</MenuItem>
                    <MenuItem value="Thabo Mofutsanyana">
                      Thabo Mofutsanyana District
                    </MenuItem>
                    <MenuItem value="Xariep">Xhariep District</MenuItem>
                  </Select>
                </Box>
              </Box>
            </Box>
            <Box>
              <LineChart isDashboard={true} data={lineGData} />
            </Box>
            <Grid container sx={{ alignItems: "center" }}>
              <Grid item xs={12} sm={12} md={4}></Grid>
              {selectedRegion === "Lejweleputswa" ||
              selectedRegion === "Thabo Mofutsanyana" ? (
                <Grid item md={4}>
                  <Button
                    sx={{
                      backgroundColor: colors.greenAccent[700],
                      color: "#000",
                      fontSize: "11px",
                      fontWeight: "bold",
                      padding: "5px 5px",
                    }}
                    onClick={() => {
                      {
                        selectedRegion === "Lejweleputswa"
                          ? getLineGraphData("Matjhabeng")
                          : getLineGraphData("Maluti a Phofung");
                      }
                    }}
                  >
                    {selectedRegion === "Lejweleputswa"
                      ? "View Matjhabeng Breakdown"
                      : "View Maluti a Phofung Breakdown "}
                  </Button>
                </Grid>
              ) : null}
              <Grid item xs={12} sm={12} md={4}></Grid>
            </Grid>
          </Box>
          <Box gridColumn="span 4" gridRow="span 2">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              backgroundColor={colors.primary[400]}
              colors={colors.grey[100]}
              height={"80px"}
              p="15px"
            >
              <Typography
                color={colors.grey[100]}
                variant="h5"
                fontWeight="600"
              >
                Regional Aggregates
              </Typography>
            </Box>
            <Box height={"400px"} overflow="auto">
              {regionalTotals &&
                regionalTotals.map((item, index) => {
                  return (
                    <Card flex={1} style={{ margin: "5px 0" }} key={index}>
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          textTransform={"uppercase"}
                        >
                          {item.total}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          fontWeight={"bold"}
                        >
                          {item.municipality}
                        </Typography>
                      </CardContent>
                    </Card>
                  );
                })}
            </Box>
          </Box>

          {/* ROW 3 */}
          <Box gridColumn="span 8" gridRow="span 2" mt={"24%"}>
            <Box
              gridColumn="span 12"
              mt="25px"
              p="0 30px"
              display="flex "
              justifyContent="space-between"
              alignItems="center"
              backgroundColor={colors.primary[400]}
            >
              <Box gridColumn="span 6" padding={"10px"}>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color={colors.grey[100]}
                  sx={{ marginBottom: 3 }}
                >
                  Provincial Numbers
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                marginBottom="10px"
              ></Box>
            </Box>
            <Box height="250px" mt="8px" mr="20px">
              <BarChart isDashboard={true} />
            </Box>
          </Box>
          <Box gridColumn="span 4" gridRow="span 2" mt={"55%"}>
            <Box
              gridColumn="span 6"
              padding={"10px"}
              backgroundColor={colors.primary[400]}
            >
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
                sx={{ marginBottom: 3 }}
              >
                Issues Captured
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              alignContent="center"
              mt="20px"
              width="100%"
            >
              <PieChart isDashboard={true} />
              {/* <Typography
          variant="h5"
          color={colors.greenAccent[500]}
          sx={{ mt: "15px" }}
        >
          $48,352 revenue generated
        </Typography>
        <Typography>Includes extra misc expenditures and costs</Typography> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
