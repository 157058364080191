import React, { useState, useContext } from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";
//import icons from react icons
import { FaPeopleArrows, FaSms, FaVoteYea } from "react-icons/fa";
import { FiHome, FiLogOut } from "react-icons/fi";
import { RiTeamLine } from "react-icons/ri";
//
import { AuthContext } from "../../components/AuthContext";
//import sidebar css from react-pro-sidebar module and our custom css
import "react-pro-sidebar/dist/css/styles.css";
import "./Header/Header.css";
import secureLocalStorage from "react-secure-storage";

const Sidebar = () => {
  const navigate = useNavigate();
  //create initial menuCollapse state using useState hook
  const [menuCollapse, setMenuCollapse] = useState(false);

  //create a custom function that will change menucollapse state from false to true and true to false
  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };
  const [user, setUser] = useContext(AuthContext);
  //
  const handleLogout = () => {
    secureLocalStorage.removeItem("usertoken");
    setUser(null);
    navigate("/");
  };
  return (
    <>
      <div id="header">
        <ProSidebar collapsed={menuCollapse}>
          <SidebarHeader>
            <div className="logotext">
              {/* small and big change using menucollapse state */}
              <p>{menuCollapse ? "Vote Scan" : "Vote Scan"}</p>
            </div>
            <div className="closemenu" onClick={menuIconClick}>
              {/* changing menu collapse icon on click */}
            </div>
          </SidebarHeader>
          <SidebarContent>
            <Menu iconShape="square">
              {user.Role === "LET Coordinator" ||
              user.Role === "RET Coordinator" ||
              user.Role === "OP User" ? null : (
                <MenuItem
                  className="menu-item"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <div>
                    <FiHome size={20} />
                  </div>
                  <span className="menu-item-text">Dashboard</span>
                </MenuItem>
              )}
              <MenuItem
                className="menu-item"
                onClick={() => {
                  navigate("/canvassing");
                }}
              >
                <div>
                  <FaPeopleArrows size={19} />
                </div>
                <span className="menu-item-text">Canvassing</span>
              </MenuItem>
              <MenuItem
                className="menu-item"
                onClick={() => {
                  navigate("/electionDay");
                }}
              >
                <div>
                  <FaVoteYea size={20} />
                </div>
                <span className="menu-item-text">Elections</span>
              </MenuItem>
              {user.Role === "LET Coordinator" ||
              user.Role === "RET Coordinator" ||
              user.Role === "OP User" ? null : (
                <MenuItem
                  className="menu-item"
                  onClick={() => {
                    navigate("/users");
                  }}
                >
                  <div>
                    <RiTeamLine size={20} />
                  </div>
                  <span className="menu-item-text">Team</span>
                </MenuItem>
              )}
              {user.Role === "LET Coordinator" ||
              user.Role === "RET Coordinator" ||
              user.Role === "OP User" ? null : (
                <MenuItem className="menu-item">
                  <div>
                    <FaSms size={20} />
                  </div>
                  <span className="menu-item-text">Bulk SMS</span>
                </MenuItem>
              )}
            </Menu>
          </SidebarContent>
          <SidebarFooter>
            <Menu iconShape="square" onClick={handleLogout}>
              <MenuItem icon={<FiLogOut />}>Logout</MenuItem>
            </Menu>
          </SidebarFooter>
        </ProSidebar>
      </div>
    </>
  );
};

export default Sidebar;
