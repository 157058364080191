import {
  Box,
  Button,
  TextField,
  MenuItem,
  IconButton,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Header from "../../components/Header";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const RegisterUser = () => {
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isloading, setLoading] = useState(false);
  //
  const handleFormSubmit = async (values) => {
    try {
      const res = await axios.post(
        "https://ancfreestate.azurewebsites.net/User",
        values
      );
      setLoading(false);
      let retcode = res.data;
      if (retcode === 1) {
        alert("User created");
        navigate("/users");
      } else {
        alert("Could not create a User, Try again");
      }
      console.log(res.data);
    } catch (error) {
      setLoading(false);
      console.log("post error =>" + error);
    }
  };

  return (
    <Box m="20px" paddingTop="65px" paddingLeft="70px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="CREATE USER" subtitle="Create a New User Profile" />
        <Box
          display="flex"
          backgroundColor={colors.greenAccent[300]}
          borderRadius="3px"
        >
          <InputBase
            sx={{ ml: 2, flex: 1 }}
            placeholder="First 6 digits of your ID"
          />
          <IconButton type="button" sx={{ p: 1 }}>
            <SearchIcon />
          </IconButton>
        </Box>
      </Box>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="First Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Surname"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.surname}
                name="surname"
                error={!!touched.surname && !!errors.surname}
                helperText={touched.surname && errors.surname}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={!!touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Cell Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.cell}
                name="cell"
                error={!!touched.cell && !!errors.cell}
                helperText={touched.cell && errors.cell}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="VD Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.voting_district}
                name="voting_district"
                error={!!touched.voting_district && !!errors.voting_district}
                helperText={touched.voting_district && errors.voting_district}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Ward"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.ward}
                name="ward"
                error={!!touched.ward && !!errors.ward}
                helperText={touched.ward && errors.ward}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                select
                fullWidth
                variant="filled"
                type="text"
                label="Select Delegation"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.delegation}
                name="delegation"
                error={!!touched.delegation && !!errors.delegation}
                helperText={touched.delegation && errors.delegation}
                sx={{ gridColumn: "span 2" }}
              >
                {delegationOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                select
                fullWidth
                variant="filled"
                type="text"
                label="Select Role"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.role}
                name="role"
                error={!!touched.role && !!errors.role}
                helperText={touched.role && errors.role}
                sx={{ gridColumn: "span 2" }}
              >
                {roleOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                select
                fullWidth
                variant="filled"
                type="text"
                label="Select Province"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.province}
                name="province"
                error={!!touched.province && !!errors.province}
                helperText={touched.province && errors.province}
                sx={{ gridColumn: "span 2" }}
              >
                {provinceOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                select
                fullWidth
                variant="filled"
                type="text"
                label="Select Region"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.region}
                name="region"
                error={!!touched.region && !!errors.region}
                helperText={touched.region && errors.region}
                sx={{ gridColumn: "span 2" }}
              >
                {regionOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Add New User
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  name: yup.string().required("required"),
  surname: yup.string().required("required"),
  password: yup
    .string()
    .min(5, "Password must be at least 5 characters long")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter"),

  cell: yup
    .string()
    .matches(phoneRegExp, "Cell number is not valid")
    .required("required"),
  ward: yup.string().required("required"),
  voting_district: yup.string().required("required"),
  delegation: yup.string().required("required"),
  role: yup.string().required("required"),
  province: yup.string().required("required"),
  region: yup.string().required("required"),
});
//
const initialValues = {
  name: "",
  surname: "",
  password: "",
  cell: "",
  ward: "",
  voting_district: "",
  delegation: "",
  role: "",
  province: "",
  region: "",
};

const delegationOptions = [
  { value: "pet", label: "PET" },
  { value: "ret", label: "RET" },
  { value: "bet", label: "BET" },
  { value: "let", label: "LET" },
];
//
const roleOptions = [
  { value: "elections manager", label: "Elections Manager" },
  { value: "elections admin", label: "Elections Admin" },
  { value: "elections coordinator", label: "Elections Coordinator" },
  { value: "vd coordinator", label: "VD Coordinator" },
  { value: "deputy vd coordinator", label: "Deputy VD Coordinator" },
  { value: "zonal coordinator", label: "Zonal Coordinator" },
  { value: "transport coordinator", label: "Transport Corrdinator" },
  { value: "front desk coordinator", label: "Front Desk Coordinator" },
  { value: "food coordinator", label: "Food Coordinator" },
  { value: "volunteer", label: "Volunteer" },
];
//
const provinceOptions = [
  { value: "free state", label: "Free State" },
  { value: "eastern cape", label: "Eastern Cape" },
  { value: "gauteng", label: "Gauteng" },
  { value: "kwazulu-natal", label: "KwaZulu-Natal" },
  { value: "limpopo", label: "Limpopo" },
  { value: "mpumalanga", label: "Mpumalanga" },
  { value: "northern cape", label: "Northern Cape" },
  { value: "north west", label: "North West" },
  { value: "western cape", label: "Western Cape" },
];
//
const regionOptions = [
  { value: "fezile dabi district", label: "Fezile Dabi District" },
  { value: "lejweleputswa district", label: "Lejweleputswa District" },
  { value: "mangaung metropolitan", label: "Mangaung Metropolitan" },
  {
    value: "thabo mofutsanyana district",
    label: "Thabo Mofutsanyana District",
  },
  { value: "xhariep District", label: "Xhariep District" },
];

export default RegisterUser;
