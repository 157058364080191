import React, { useEffect, useState } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import AuthNav from "./routes/AuthNav"; // Import AuthNav
import AppNav from "./routes/AppNav"; // Import AppNav
import RETNav from "./routes/RETAuth"; //import RETNav
import secureLocalStorage from "react-secure-storage";

import { AuthContext } from "./components/AuthContext";
import { jwtDecode } from "jwt-decode";

function App() {
  const [theme, colorMode] = useMode();
  const [user, setUser] = useState(); // Get isLoggedIn state from AuthContext

  useEffect(() => {
    const usertoken = secureLocalStorage.getItem("usertoken");
    if (usertoken !== null) {
      let decodedUser = jwtDecode(usertoken.token);
      setUser(decodedUser);
    }
  }, []);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <AuthContext.Provider value={[user, setUser]}>
          <CssBaseline />
          {user ? (
            user.Role === "LET Coordinator" ||
            user.Role === "OP User" ||
            user.Role === "RET Coordinator" ? (
              <RETNav />
            ) : (
              <AppNav />
            )
          ) : (
            <AuthNav />
          )}
        </AuthContext.Provider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
