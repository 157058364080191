import React, { useState, useContext } from "react";
import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { tokens } from "../../theme";
import { CssBaseline } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import No_Data from "../../components/No_Data";
import { AuthContext } from "../../components/AuthContext";
import secureLocalStorage from "react-secure-storage";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://ANC Free State">
        ancvotescan.co.za
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const SignIn = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isloading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState("");

  const [user, setUser] = useContext(AuthContext);
  //
  const handleFormSubmit = async (values, { setFieldError }) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://api.votescan.co.za/Login/getToken/${values.cell}/${values.password}`
      );
      const token = response.data;
      if (!token) {
        setLoginError("No user found with the credentials provided");
        return;
      }

      let decodedUser = getUserDetails(token.token);
      console.log(decodedUser.Role);
      if (
        decodedUser.Role === "RET Coordinator" ||
        decodedUser.Role === "LET Coordinator" ||
        decodedUser.Role === "OP User" ||
        decodedUser.Delegation === "PET" ||
        decodedUser.Role === "super user"
      ) {
        secureLocalStorage.setItem("usertoken", token);
        setUser(decodedUser);
      } else {
        setLoginError(
          "You're registered role / delegation is prohibited from accessing this dashboard."
        );
      }
    } catch (error) {
      // setLoading(false);
      setLoginError("Error cought" + error.message);
    } finally {
      setLoading(false);
    }
  };
  const getUserDetails = (token) => {
    const _user = jwtDecode(token);
    return _user;
  };
  return (
    <Box height="100%" width="100%">
      <CssBaseline />

      <Box
        m="10% 30% 0px 30%"
        p="20px 100px 20px 100px"
        display="flex"
        flexDirection="column"
        backgroundColor="#fff"
        justifyContent="center"
        borderRadius={"10px"}
        boxShadow={"1px 2px 9px gray"}
      >
        <Typography
          component="h1"
          variant="h5"
          align="center"
          sx={{ flexDirection: "column" }}
        >
          <img
            alt="Logo"
            width="100px"
            height="100px"
            src={`../../assets/ANC-Logo.png`}
            style={{
              cursor: "pointer",
              borderRadius: "50%",
            }}
          />
          <Typography variant="h5"> Sign in</Typography>
        </Typography>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkinSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Cell Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.cell}
                name="cell"
                error={!!touched.cell && !!errors.cell}
                helperText={touched.cell && errors.cell}
                autoComplete="cell"
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                error={!!touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {loginError && (
                <Typography color="error" variant="body2">
                  {loginError}
                </Typography>
              )}
              <Box display="flex" justifyContent="center" mt="20px">
                {isloading ? (
                  <No_Data />
                ) : (
                  <Button
                    type="submit"
                    fullWidth
                    color="secondary"
                    variant="contained"
                  >
                    Sign In
                  </Button>
                )}
              </Box>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2" color="inherit">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2" color="inherit">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>

        <Box mt={8}>
          <Copyright />
        </Box>
      </Box>
    </Box>
  );
};
//
const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
//
const checkinSchema = yup.object().shape({
  password: yup.string().required("Password is Required"),

  cell: yup
    .string()
    .matches(phoneRegExp, "Cell number is not valid")
    .required("Cell Number is required"),
});
//
const initialValues = {
  password: "",
  cell: "",
  rememberMe: false,
};
//

export default SignIn;
