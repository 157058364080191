import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme, Button, Grid } from "@mui/material";
import { tokens } from "../theme";
import { useNavigate } from "react-router-dom";

const ActiveElections = ({ elections }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  //
  const [recorded, setRecorded] = useState();
  //
  const [total1, setTotal] = useState();

  const getProgress = async (elect) => {
    let response;
    try {
      if (elect.ward === "provincial") {
        response = await fetch(
          `https://api.votescan.co.za/RecordedVoters/getprovinceprogress/${elect.name}/${elect.date}`
        );
      } else {
        response = await fetch(
          `https://aapi.votescan.co.za/RecordedVoters/getwardprogress/${elect.ward}`
        );
      }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setRecorded(data[0]);
    } catch (error) {
      console.log("progress fetch error =>" + error);
    }
  };
  useEffect(() => {
    getProgress(elections);
  }, []);

  return (
    <>
      {elections.name === "Nation & Provincial Elections" ? (
        <Box
          alignItems="center"
          justifyContent="center"
          marginBottom={"10px"}
          padding={"10px"}
        >
          <Box>
            <Box bgcolor={"success"}>
              <Box
                alignItems="center"
                justifyContent="center"
                marginBottom={"20px"}
                marginTop={"10px"}
              >
                <Typography
                  variant="h5"
                  fontWeight="600"
                  textTransform={"uppercase"}
                >
                  {elections.name}
                </Typography>
              </Box>
              <Grid container>
                <Grid item md={4} sm={12} xs={12}>
                  <Box
                    mr={"5px"}
                    backgroundColor={colors.greenAccent[400]}
                    style={{ width: 20, height: 20 }}
                  >
                    <Typography fontSize={17} marginLeft={"25px"}>
                      {recorded && recorded.surname}
                    </Typography>
                  </Box>
                  <Box flex="1" alignItems={"center"} flexDirection={"row"}>
                    <Typography fontSize={17} flexDirection={"row"}>
                      <Typography>Canvassed </Typography>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Box
                    mr={"5px"}
                    backgroundColor={colors.blueAccent[400]}
                    style={{ width: 20, height: 20 }}
                  >
                    <Typography fontSize={17} marginLeft={"25px"}>
                      {recorded && recorded.names}
                    </Typography>
                  </Box>
                  <Box flex="1" alignItems={"center"} flexDirection={"row"}>
                    <Typography>FS Voters </Typography>
                  </Box>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Button
                    onClick={() => navigate("/electionDay")}
                    variant="outlined"
                    color="success"
                    sx={{ width: "100%", marginTop: "-15px" }}
                  >
                    <img
                      alt="Logo"
                      width="40px"
                      height="40px"
                      src={`../../assets/dot.gif`}
                    />
                    GO TO LIVE STATS
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default ActiveElections;
