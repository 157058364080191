import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import axios from "axios";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RefreshIcon from "@mui/icons-material/Refresh";
import No_Data from "../../components/No_Data";
//
ChartJS.register(LineElement, CategoryScale, PointElement, LinearScale);
//

const Canvassing = ({ names }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  //get graph data
  const [loading, setLoading] = useState();
  const [data, setData] = useState([]);
  const [municipality, setMunicipality] = useState([]);

  //get upper stats
  const location = useLocation();
  //~
  const getWards = async (muni) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api.votescan.co.za/FrontDesk/getwardvds/${muni}`
      );
      const res = response.data;
      setData(res);
      setLoading(false);
    } catch (error) {
      console.log("wards stats =>" + error);
    }
  };
  const handleReload = () => {
    processLoad();
  };
  const processLoad = () => {
    const { id } = location.state;
    getWards(id);
  };
  useEffect(() => {
    const { id } = location.state;
    getWards(id);
  }, []);

  //
  if (data) {
    var wardChartData = {
      labels: data.map((item) => item.vdName),
      datasets: [
        {
          label: "Total Scanned ",
          data: data.map((item) => item.fullName),

          backgroundColor: ["hsl(111, 70%, 50%)"],
          borderColor: ["hsl(96, 70%, 50%)"],
          borderWidth: 1,
          grouped: true,
          //barThickness: 8,
        },
      ],
    };
  } //
  var options = {
    maintianAspectRation: true,
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    legend: {
      labels: {
        fontSize: 10,
      },
    },
  };

  return (
    <Box m="20px" paddingTop="65px" paddingLeft="100px">
      <Grid container>
        <Grid item md={1} sm={1} xs={12} spacing={2}>
          <IconButton
            aria-label="delete"
            color="success"
            onClick={() => {
              navigate(-1);
              // navigate(`/vdstats/`, {
              //   state: { id: muni.wardID },
              // });
            }}
          >
            <ArrowBackIcon />
            <Typography
              variant="h5"
              fontWeight="600"
              alignItems={"center"}
              alignContent={"center"}
              fontSize={"10pt"}
              color={"success"}
            >
              go back
            </Typography>
          </IconButton>
        </Grid>
        <Grid item md={9} sm={9} xs={12} spacing={2}>
          <Header title={location.state.id} subtitle="Showing VDs in ward" />
        </Grid>
        <Grid item md={2} sm={2} xs={12} spacing={2}>
          <IconButton
            aria-label="delete"
            color="success"
            size="large"
            onClick={handleReload}
          >
            <Typography
              variant="h5"
              fontWeight="600"
              alignItems={"center"}
              alignContent={"center"}
              color={"success"}
            >
              Refresh stats
            </Typography>
            <RefreshIcon fontSize="40px" />
          </IconButton>
        </Grid>
      </Grid>

      <Box justifyContent="space-between" alignItems="center">
        <Grid
          container
          marginY={"10px"}
          marginX={"5px"}
          sx={{ paddingBottom: "5%" }}
        >
          {loading ? (
            <No_Data />
          ) : (
            <Grid container spacing={2}>
              {data &&
                data.map((muni, index) => {
                  return (
                    <Grid item md={3} sm={6} xs={12} spacing={2} key={index}>
                      <Card>
                        <CardContent>
                          <Grid container>
                            <Grid item md={6} sm={12} xs={12} spacing={2}>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                textTransform={"uppercase"}
                                marginTop={"15px"}
                              >
                                {muni.vdName}
                              </Typography>
                              <Typography
                                gutterBottom
                                variant="h6"
                                component="div"
                                textTransform={"uppercase"}
                                marginTop={"15px"}
                              >
                                {muni.vdNumber}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              md={2}
                              sm={12}
                              xs={12}
                              spacing={2}
                              alignItems={"center"}
                              alignContent={"center"}
                            >
                              <Typography
                                fontWeight={"bold"}
                                fontSize={"19pt"}
                                marginTop={"-10px"}
                              >
                                {muni.fullName}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
            </Grid>
          )}
        </Grid>
        <Grid container>
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "20px 20px 0 20px" }}
          >
            VD Stats
          </Typography>
          <Line data={wardChartData} options={options} height="90%" />
        </Grid>
      </Box>
    </Box>
  );
};

export default Canvassing;
