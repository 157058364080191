import { Box, useTheme } from "@mui/material";
import { tokens } from "../theme";
import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  BarElement,
  LinearScale,
  CategoryScale,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { CategoryRounded } from "@mui/icons-material";

ChartJS.register(CategoryScale, LinearScale, BarElement);

const BarChart = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  //
  const getData = () => {
    fetch(`https://api.votescan.co.za/Reports/getprovincialnumbers`)
      .then((response) => response.json())
      .then((res) => setData(res));
  };
  useEffect(() => {
    getData();
  }, []);

  var chartData = {
    labels: data.map(
      (item) => item.municipality.split(" - ")[1] + " - " + item.recorded
    ),
    datasets: [
      {
        label: "Recorded",
        data: data.map((item) => item.recorded),

        backgroundColor: ["hsl(229, 70%, 50%)"],
        borderColor: ["hsl(224, 70%, 50%)"],
        borderWidth: 1,
        grouped: true,
      },
      {
        label: "Special",
        data: data.map((item) => item.special),

        backgroundColor: ["hsl(111, 70%, 50%)"],
        borderColor: ["hsl(96, 70%, 50%)"],
        borderWidth: 1,
        grouped: true,
      },
      {
        label: "Total",
        data: data.map((item) => item.total),

        backgroundColor: ["hsl(344, 70%, 50%)"],
        borderColor: ["hsl(334, 70%, 50%)"],
        borderWidth: 1,
        grouped: true,
      },
    ],
  };
  //
  var options = {
    maintianAspectRation: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    legend: {
      labels: {
        fontSize: 10,
        position: "right",
      },
    },
  };
  //
  return (
    <Box>
      <Bar
        data={chartData}
        height="150px"
        maintianAspectRation={false}
        options={options}
      />
    </Box>
  );
};
export default BarChart;
