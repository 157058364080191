import React, { useState, useEffect } from "react";
import { Box, Grid, Card, CardContent, Typography } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";
import { Height } from "@mui/icons-material";
//
ChartJS.register(LineElement, CategoryScale, PointElement, LinearScale);
//

const Canvassing = ({ names }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  //get graph data
  const [data, setData] = useState([]);
  //get upper stats
  const [stats, setStats] = useState([]);
  //get attitudes
  const [attitudes, setAttitudes] = useState([]);
  //get provincial age demographics
  const [provincialAges, setProvincialAges] = useState([]);
  //get municipal age demographics
  const [municipalAges, setMunicipalAges] = useState([]);
  //loading
  const [loading, setLoading] = useState();
  //
  const [selectedCellNumber, setSelectedCellNumber] = useState("");
  const [userData, setUserData] = useState([]);
  //
  const getData = () => {
    fetch(`https://api.votescan.co.za/Reports/getprovincialnumbers`)
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        setData(res);
      });
  };
  //get attitudes
  const getAttitudes = async () => {
    try {
      const url = `https://api.votescan.co.za/Reports/getattitude`;
      let result = await fetch(url);
      result = await result.json();
      setAttitudes(result);
    } catch (error) {
      console.log("get attitudes fetch =>" + error);
    }
  };
  //get provincial stats
  const getProvincialStats = () => {
    fetch(`https://api.votescan.co.za/Reports/getprovincialstats`)
      .then((response) => response.json())
      .then((res) => {
        setStats(res[0]);
      });
  };
  //get provincial age demographics
  const getProvincialAgeDemographics = async () => {
    try {
      const url = `https://api.votescan.co.za/Reports/getprovincialagedemographics`;
      let result = await fetch(url);
      result = await result.json();
      setProvincialAges(result[0]);
    } catch (error) {
      console.log("get provincial ages demographics fetch =>" + error);
    }
  };
  //get municipal age demographics
  const getMunicipalAgeDemographics = async () => {
    try {
      const url = `https://api.votescan.co.za/Reports/getmunicipalagedemographics`;
      let result = await fetch(url);
      result = await result.json();
      setMunicipalAges(result);
    } catch (error) {
      console.log("get provincial ages demographics fetch =>" + error);
    }
  };

  //Users Bar Graph
  var provincialChartcaData = {
    labels: data.map((item) => item.municipality),
    datasets: [
      {
        label: "Total Canvassed ",
        data: data.map((item) => item.recorded),

        backgroundColor: ["hsl(229, 70%, 50%)"],
        borderColor: ["hsl(224, 70%, 50%)"],
        borderWidth: 1,
        grouped: true,
        //barThickness: 8,
      },
      {
        label: "Special Voters",
        data: data.map((item) => item.special),

        backgroundColor: ["hsl(111, 70%, 50%)"],
        borderColor: ["hsl(96, 70%, 50%)"],
        borderWidth: 1,
        grouped: true,
      },
    ],
  };
  //Users Bar Graph
  if (municipalAges) {
    var ageChartcaData = {
      labels: municipalAges.map((item) => item.municipality),
      datasets: [
        {
          label: "Youth (<35) ",
          data: municipalAges.map((item) => item.youth),

          backgroundColor: ["hsl(229, 70%, 50%)"],
          borderColor: ["hsl(224, 70%, 50%)"],
          borderWidth: 1,
          grouped: true,
          //barThickness: 8,
        },
        {
          label: "Between 35 to 50",
          data: municipalAges.map((item) => item.between35_50),

          backgroundColor: ["hsl(111, 70%, 50%)"],
          borderColor: ["hsl(96, 70%, 50%)"],
          borderWidth: 1,
          grouped: true,
        },
        {
          label: "Between 50 to 65",
          data: municipalAges.map((item) => item.between50_65),

          backgroundColor: ["hsl(344, 70%, 50%)"],
          borderColor: ["hsl(334, 70%, 50%)"],
          borderWidth: 1,
          grouped: true,
        },
        {
          label: "Over 65",
          data: municipalAges.map((item) => item.over_65),

          backgroundColor: ["hsl(274, 70%, 50%)"],
          borderColor: ["hsl(291, 70%, 50%)"],
          borderWidth: 1,
          grouped: true,
        },
      ],
    };
  }

  //
  var options = {
    maintianAspectRation: true,
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    legend: {
      labels: {
        fontSize: 10,
      },
    },
  };
  useEffect(() => {
    const start = () => {
      getData();
      getAttitudes();
      getProvincialStats();
      getProvincialAgeDemographics();
      getMunicipalAgeDemographics();
      setLoading(false);
    };
    start();
    setLoading(true);
  }, []);

  return (
    <Box m="20px" paddingTop="65px" paddingLeft="100px">
      <Header title="Canvassing" subtitle="Latest Canvassing Records" />
      {loading ? (
        <Typography variant="body2" color="text.secondary">
          Total number of contacted voters in the Free State so far
        </Typography>
      ) : null}
      <Grid container marginY={"30px"} spacing={2}>
        <Grid item xs={12} sm={4} md={4}>
          <Card>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                textTransform={"uppercase"}
              >
                Canvassed total
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Total number of contacted voters in the Free State so far
              </Typography>
              <Typography fontWeight={"bold"} fontSize={"19pt"}>
                {stats.total_1}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={64} md={4}>
          <Card>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                textTransform={"uppercase"}
              >
                Provincial Voters
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Total number of contacted voters in the Free State so far
              </Typography>
              <Typography fontWeight={"bold"} fontSize={"19pt"}>
                {stats.total_2}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Card>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                textTransform={"uppercase"}
              >
                ANC Team
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Total number of contacted voters in the Free State so far
              </Typography>
              <Typography fontWeight={"bold"} fontSize={"19pt"}>
                {stats.total_3}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "20px 20px 0 20px" }}
          >
            Provincial numbers
          </Typography>
          <Line data={provincialChartcaData} options={options} height="90%" />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container marginY={"30px"} spacing={2}>
            <Typography
              variant="h5"
              fontWeight="600"
              sx={{ padding: "20px 20px 0 20px" }}
            >
              Provincial age demographics
            </Typography>
          </Grid>
          <Grid container spacing={2} marginY={"10px"}>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    textTransform={"uppercase"}
                  >
                    Youth - Age 18 to 35
                  </Typography>
                  <Typography fontWeight={"bold"} fontSize={"19pt"}>
                    {provincialAges ? provincialAges.youth : 0}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    textTransform={"uppercase"}
                  >
                    Between 35 to 50
                  </Typography>
                  <Typography fontWeight={"bold"} fontSize={"19pt"}>
                    {provincialAges.between35_50}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    textTransform={"uppercase"}
                  >
                    Between 50 to 65
                  </Typography>
                  <Typography fontWeight={"bold"} fontSize={"19pt"}>
                    {provincialAges.between50_65}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    textTransform={"uppercase"}
                  >
                    Over 65 Yeards
                  </Typography>
                  <Typography fontWeight={"bold"} fontSize={"19pt"}>
                    {provincialAges.over_65}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container marginY={"30px"} spacing={2}>
            <Typography
              variant="h5"
              fontWeight="600"
              sx={{ padding: "20px 20px 0 20px" }}
            >
              Provincial age demographics
            </Typography>
          </Grid>
          <Line data={ageChartcaData} options={options} height="90%" />
        </Grid>
      </Grid>

      <Grid container marginY={"30px"} spacing={2}>
        <Typography
          variant="h5"
          fontWeight="600"
          sx={{ padding: "20px 20px 0 20px" }}
        >
          Municipality attitude stats
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {attitudes.map((item, index) => {
            return (
              <Card key={index} marginY={"10px"}>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    textTransform={"uppercase"}
                  >
                    {item.municipality}
                  </Typography>
                  <Grid container>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body2" color="text.secondary">
                        Canvassed
                        <Typography fontWeight={"bold"} fontSize={"19pt"}>
                          {item.total}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <Typography variant="body2" color="text.secondary">
                        ANC Supporters
                        <Typography fontWeight={"bold"} fontSize={"19pt"}>
                          {item.anc_supporters}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <Typography variant="body2" color="text.secondary">
                        Non ANC Supporters
                        <Typography fontWeight={"bold"} fontSize={"19pt"}>
                          {item.non_supporters}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <Typography variant="body2" color="text.secondary">
                        Undecided
                        <Typography fontWeight={"bold"} fontSize={"19pt"}>
                          {item.undecided}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <Typography variant="body2" color="text.secondary">
                        Not Captured
                        <Typography fontWeight={"bold"} fontSize={"19pt"}>
                          {item.not_captured}
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            );
          })}
        </Box>
      </Grid>
    </Box>
  );
};

export default Canvassing;
