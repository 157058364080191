import React from "react";
//dimensions
//
import loader from "./loader.gif";
const No_Data = () => {
  return (
    <div>
      <img src={loader} resizeMode="contain" height={100} width={100} />
    </div>
  );
};
export default No_Data;
