import React, { useState, useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";

const Elections = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [elections, setElections] = useState([]);
  //
  const getElections = () => {
    fetch(`https://ancfreestate.azurewebsites.net/Elections`)
      .then((response) => response.json())
      .then((res) => setElections(res));
  };
  useEffect(() => {
    getElections();
  }, []);
  return (
    <Box m="20px" paddingTop="65px" paddingLeft="80px">
      <Header title="Elections" subtitle="All Elections" />

      {elections.map((item, index) => (
        <Accordion
          defaultExpanded
          sx={{ backgroundColor: colors.primary[400] }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            key={index}
            id={index}
          >
            <Typography color={colors.greenAccent[500]} variant="h5">
              {item.name}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{item.desc}</Typography>
            <Typography
              color={item.status === "ended" ? "red" : colors.greenAccent[400]}
            >
              {item.status}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default Elections;
