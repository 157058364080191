import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Typography,
  useTheme,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import {
  Chart as ChartJS,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import No_Data from "../../components/No_Data";
import axios from "axios";
import RefreshIcon from "@mui/icons-material/Refresh";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { AuthContext } from "../../components/AuthContext";
//
ChartJS.register(LineElement, CategoryScale, PointElement, LinearScale);

const ElectionDay = () => {
  const navigate = useNavigate();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [municipalities, setMunicipalities] = useState([]);
  //
  const [loading1, setLoading1] = useState(false);
  //
  const [user, setUser] = useContext(AuthContext);

  const getprovincialmunicipalities = (
    delegation,
    role,
    region,
    municipality
  ) => {
    setLoading1(true);
    axios
      .get(`https://api.votescan.co.za/FrontDesk/getprovincialmunicipalities`)
      .then((res) => {
        if (delegation === "RET" && role === "RET Coordinator") {
          processRET(region, res.data);
        }
        if (
          delegation === "LET" &&
          (role === "LET Coordinator" || role === "OP User")
        ) {
          processLET(municipality);
        }
        if (role === "super user" || delegation === "PET") {
          setMunicipalities(res.data);
        }
        setLoading1(false);
      })
      .catch((error) => {
        setLoading1(false);
        console.log(error);
      });
  };
  const processLET = (municipality) => {
    navigate(`/wardstats/`, {
      state: { id: municipality },
    });
  };
  const processRET = (region, municipalities) => {
    let municipalityArr = [];

    if (region === "Fezile Dabi Region") {
      for (let i = 0; i < municipalities.length; i++) {
        if (
          municipalities[i].municipality === "FS201 - Moqhaka" ||
          municipalities[i].municipality === "FS203 - Ngwathe" ||
          municipalities[i].municipality === "FS204 - Metsimaholo" ||
          municipalities[i].municipality === "FS205 - Mafube"
        ) {
          municipalityArr.push(municipalities[i]);
        }
      }
    }
    if (region === "Mangaung Region") {
      for (let i = 0; i < municipalities.length; i++) {
        if (municipalities[i].municipality === "MAN - Mangaung") {
          municipalityArr.push(municipalities[i]);
        }
      }
    }
    if (region === "Lejweleputswa Region") {
      for (let i = 0; i < municipalities.length; i++) {
        if (
          municipalities[i].municipality === "FS181 - Masilonyana" ||
          municipalities[i].municipality === "FS184 - Matjhabeng" ||
          municipalities[i].municipality === "FS185 - Nala" ||
          municipalities[i].municipality === "FS182 - Tokologo" ||
          municipalities[i].municipality === "FS183 - Tswelopele"
        ) {
          municipalityArr.push(municipalities[i]);
        }
      }
    }
    if (region === "Thabo Mofutsanyane Region") {
      for (let i = 0; i < municipalities.length; i++) {
        if (
          municipalities[i].municipality === "FS195 - Phumelela" ||
          municipalities[i].municipality === "FS194 - Maluti a Phofung" ||
          municipalities[i].municipality === "FS193 - Nketoana" ||
          municipalities[i].municipality === "FS192 - Dihlabeng" ||
          municipalities[i].municipality === "FS191 - Setsoto" ||
          municipalities[i].municipality === "FS196 - Mantsopa"
        ) {
          municipalityArr.push(municipalities[i]);
        }
      }
    }
    if (region === "Xhariep Region") {
      //
      for (let i = 0; i < municipalities.length; i++) {
        if (
          municipalities[i].municipality === "FS162 - Kopanong" ||
          municipalities[i].municipality === "FS161 - Letsemeng" ||
          municipalities[i].municipality === "FS163 - Mohokare"
        ) {
          municipalityArr.push(municipalities[i]);
        }
      }
    }
    setMunicipalities([]);
    setMunicipalities(municipalityArr);
  };
  const handleReload = () => {
    processLoad();
  };
  const processLoad = () => {
    let _delegation = user.Delegation;
    let _role = user.Role;
    let _region = user.Region;
    let _municipality = user.Municipality;
    console.log("we are here", _delegation, _role);
    getprovincialmunicipalities(_delegation, _role, _region, _municipality);
  };
  useEffect(() => {
    processLoad();
  }, []);
  //GRAPH DATA
  //Users Bar Graph
  if (municipalities) {
    var municipalitiesChartcaData = {
      labels: municipalities.map((item) => item.municipality),
      datasets: [
        {
          label: "Total Scanned ",
          data: municipalities.map((item) => item.fullName),

          backgroundColor: ["hsl(111, 70%, 50%)"],
          borderColor: ["hsl(96, 70%, 50%)"],
          borderWidth: 1,
          grouped: true,
          //barThickness: 8,
        },
      ],
    };
  } //
  var options = {
    maintianAspectRation: true,
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    legend: {
      labels: {
        fontSize: 10,
      },
    },
  };
  return (
    <Box m="20px" paddingTop="65px" paddingLeft="100px" bgcolor={"#fcfcfc"}>
      <Grid container>
        <Grid item md={10} sm={12} xs={12}>
          {user.Delegation === "RET" ? (
            <Header title={user.Region} subtitle={`Showing Regional stats`} />
          ) : null}
        </Grid>
        <Grid item md={2} sm={12} xs={12} sx={{ textAlign: "end" }}>
          <IconButton
            aria-label="delete"
            color="success"
            size="large"
            onClick={handleReload}
          >
            <Typography
              variant="h5"
              fontWeight="600"
              alignItems={"center"}
              alignContent={"center"}
              color={"success"}
            >
              Refresh stats
            </Typography>
            <RefreshIcon fontSize="40px" />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container marginY={"30px"} spacing={2}>
        <img
          alt="Logo"
          width="40px"
          height="40px"
          src={`../../assets/dot.gif`}
          style={{
            marginTop: "10px",
          }}
        />
        <Typography
          variant="h5"
          fontWeight="600"
          sx={{ padding: "20px 20px 0 20px" }}
        >
          ELECTION DAY LIVE STATS
        </Typography>
        <Grid
          container
          marginY={"10px"}
          marginX={"5px"}
          sx={{ paddingBottom: "5%" }}
        >
          {loading1 ? (
            <No_Data />
          ) : (
            <Grid container spacing={2}>
              {municipalities &&
                municipalities.map((muni, index) => {
                  return (
                    <Grid item md={3} sm={6} xs={12} spacing={2} key={index}>
                      <Card>
                        <CardContent>
                          <Grid container>
                            <Grid item md={6} sm={12} xs={12} spacing={2}>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                textTransform={"uppercase"}
                                marginTop={"15px"}
                              >
                                {muni.municipality}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              md={2}
                              sm={12}
                              xs={12}
                              spacing={2}
                              alignItems={"center"}
                              alignContent={"center"}
                            >
                              <Typography
                                fontWeight={"bold"}
                                fontSize={"19pt"}
                                marginTop={"-10px"}
                              >
                                {muni.fullName}
                              </Typography>
                            </Grid>
                            <Grid item md={4} sm={12} xs={12} spacing={2}>
                              <IconButton
                                aria-label="delete"
                                color="success"
                                onClick={() => {
                                  navigate(`/wardstats/`, {
                                    state: { id: muni.municipality },
                                  });
                                }}
                              >
                                <Typography
                                  variant="h5"
                                  fontWeight="600"
                                  alignItems={"center"}
                                  alignContent={"center"}
                                  fontSize={"10pt"}
                                  color={"success"}
                                >
                                  view wards
                                </Typography>
                                <ArrowForwardIosIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
            </Grid>
          )}
        </Grid>
        <Grid container>
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "20px 20px 0 20px" }}
          >
            Provincial numbers
          </Typography>
          <Line
            data={municipalitiesChartcaData}
            options={options}
            height="90%"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ElectionDay;
