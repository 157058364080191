import {
  Avatar,
  Box,
  IconButton,
  useTheme,
  Typography,
  useMediaQuery,
  Stack,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { AuthContext } from "../../components/AuthContext";

//
const SIDE_NAV_WIDTH = 0;
const TOP_NAV_HEIGHT = 64;
//
const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  //
  const [user, setUser] = useContext(AuthContext);

  return (
    <>
      <Box
        component="header"
        backgroundColor={"#fff"}
        boxShadow={"0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15)"}
        sx={{
          backdropFilter: "blur(6px)",
          position: "fixed",
          left: {
            lg: `${SIDE_NAV_WIDTH}px`,
          },
          top: 0,
          width: "100%",
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 2,
          }}
        >
          <Stack alignItems="center" direction="row" spacing={2}>
            <Tooltip title="Logo">
              <img
                alt="Logo"
                width="70px"
                height="70px"
                src={`../../assets/ANC-Logo.png`}
                style={{
                  cursor: "pointer",
                }}
              />
              <img
                alt="Logo"
                width="50px"
                height="55px"
                src={`../../assets/icon.png`}
                style={{
                  cursor: "pointer",
                }}
              />
            </Tooltip>
            {lgUp ? (
              <Typography
                variant="h4"
                color={colors.grey[100]}
                fontWeight="bold"
                mt="24px"
              >
                ANC FS | Vote Scan Dashboard
              </Typography>
            ) : null}
          </Stack>
          <Stack alignItems="center" direction="row" spacing={2}>
            <Typography
              color={colors.grey[100]}
              variant="h6"
              fontWeight="600"
              sx={{ mt: 1.5 }}
            >
              Download App
            </Typography>
            <IconButton
              onClick={() => {
                window.open(
                  "https://play.google.com/store/apps/details?id=com.votescan&hl=en&gl=US&pli=1",
                  "_blank"
                );
              }}
            >
              <img
                alt="Google Play Logo"
                width="95px"
                height="40px"
                src={`../../googleplay.png`}
                style={{ cursor: "pointer" }}
              />
            </IconButton>
            <IconButton
              onClick={() => {
                window.open(
                  "https://appgallery.huawei.com/app/C109473039",
                  "_blank"
                );
              }}
            >
              <img
                alt="Expo Logo"
                width="95px"
                height="30px"
                src={`../../appgallery.png`}
                style={{ cursor: "pointer" }}
              />
            </IconButton>
            <IconButton
              sx={{ borderWidth: "1px", borderColor: "lightgray" }}
              onClick={() => {
                window.open(
                  "https://expo.dev/@ancfreestate/voterscan",
                  "_blank"
                );
              }}
            >
              <img
                alt="Expo Go"
                width="65px"
                height="35px"
                src={`../../expogo.png`}
                style={{
                  cursor: "pointer",
                  padding: 2,
                  border: "solid 1px lightgray",
                  borderRadius: 10,
                }}
              />
            </IconButton>
            <IconButton onClick={colorMode.toggleColorMode}>
              {theme.palette.mode === "dark" ? (
                <DarkModeOutlinedIcon />
              ) : (
                <LightModeOutlinedIcon />
              )}
            </IconButton>
            <IconButton>
              <Typography marginX={"10px"}>
                {user.Name} {user.Surname}
                <Typography fontSize={"9pt"} fontWeight={"bold"}>
                  {user.Role} | {user.Municipality}
                </Typography>
              </Typography>
              <Avatar sx={{ backgroundColor: "purple" }}>
                {user.Delegation}
              </Avatar>
            </IconButton>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default Topbar;
