// AuthNav.js
import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "../scenes/form/LogIn";

const AuthNav = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
      </Routes>
    </>
  );
};

export default AuthNav;
