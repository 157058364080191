// AppNav.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../scenes/dashboard/Dashboard";
import Users from "../scenes/users/Users";
import Canvassing from "../scenes/canvassing/Canvassing";
import ProvincialNumbers from "../scenes/reports/ProvincialNumbers";
import RegisterUser from "../scenes/form/RegisterUser";
import ResultSlip from "../scenes/reports/ResultSlip";
import IssuesCaptured from "../scenes/reports/IssuesCaptured";
import Elections from "../scenes/elections/Elections";
import ElectionDay from "../scenes/elections/ElectionDay";
import Topbar from "../scenes/navigation/Topbar";
import Sidebar from "../scenes/navigation/Sidebar";
import { Navigate } from "react-router-dom/dist";
import WardStats from "../scenes/elections/WardStats";
import VDStats from "../scenes/elections/VDStats";

const AppNav = () => {
  return (
    <>
      <Topbar />
      <Sidebar />

      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/users" element={<Users />} />
        <Route path="/canvassing" element={<Canvassing />} />
        <Route path="/provincial-numbers" element={<ProvincialNumbers />} />
        <Route path="/register-user" element={<RegisterUser />} />
        <Route path="/result-slip" element={<ResultSlip />} />
        <Route path="/issues-captured" element={<IssuesCaptured />} />
        <Route path="/elections" element={<Elections />} />
        <Route path="/electionDay" element={<ElectionDay />} />
        <Route path="/wardstats" element={<WardStats />} />
        <Route path="/vdstats" element={<VDStats />} />
      </Routes>
    </>
  );
};

export default AppNav;
